<template>
  <div>
    <table class="ui basic table">
      <thead>
        <tr>
          <th>
            <div class="ui checkbox">
              <input type="checkbox" v-model="selectAll" @click="select">
              <label></label>
            </div>
          </th>
          <th
            v-for="(head, i) in thead"
            v-html="head.label"
            :key="i"
            class="table-header"
          />
        </tr>
      </thead>
      <tbody class="basic-table-body">
        <tr v-if="loading">
          <td v-for="(item, k) in [...Array(5)]" :key="k">
            <t-placeholder :lines="1" />
          </td>
        </tr>
        <tr v-else v-for="(items, i) in bankslips" :key="i">
          <td class="center aligned columns extra-options">
            <div class="ui checkbox">
              <input type="checkbox" :value="itemId(items)" v-model="selected">
              <label></label>
            </div>
          </td>
          <td
            class="columns"
            v-for="(item, k) in formatedData(items)"
            :key="k"
            v-html="item"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { formatStatusBankSlip, formatDate, formatValueCurrency } from '@/helpers'

export default {
  name: 'TBankSlipTable',
  data () {
    return {
      thead: [
        {label: 'Download', name: 'download'},
        {label: 'Data Geração', name: 'generatedDate'},
        {label: 'Data Vencimento', name: 'dueDate'},
        {label: 'No. de Boletos', name: 'billetqty'},
        {label: 'Valor Total Cobrado', name: 'totalAmount'}
      ],
      selected: [],
      selectAll: false
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    bankslips: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    selected (newV, oldV) {
      if (this.selected && this.bankslips) {
        if (this.selected.length !== this.bankslips.length) {
          this.selectAll = false
        } else {
          this.selectAll = true
        }
      }
      this.setTotalSelected(this.selected.length)
    }
  },
  methods: {
    ...mapActions('bankslip', [ 'setTotalSelected' ]),
    itemId: item => item.id,
    formatedData (data) {
      return {
        download: formatStatusBankSlip(data.download),
        generatedDate: formatDate(data.generatedDate),
        dueDate: formatDate(data.dueDate),
        billetQty: data.billetQty,
        totalAmount: formatValueCurrency(data.totalAmount)
      }
    },
    select () {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.bankslips) {
          this.selected.push(this.bankslips[i].id);
        }
      }
    }
  }
}
</script>

<style lang="scss" src="./table-files.scss"></style>

<style lang="scss" scoped>
@import '@/assets/sass/colors';

.ui.table thead tr:first-child > th:last-child {
  text-align: right;
}

.ui.basic.table tbody.basic-table-body td:nth-child(1) {
  padding-left: 10px;
}

.ui.checkbox label:before,
.ui.checkbox input:not(:checked) ~ label::before {
  border-color: #dddcdc;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.ui.checkbox input:checked ~ label:after {
  content: no-open-quote;
  color: $blue-300;
  background-color: $blue-300;
  width: 10px;
  height: 10px;
  top: 5px;
  left: 5px;
}
</style>
