var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "ui basic table" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _c("th", [
              _c("div", { staticClass: "ui checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectAll,
                      expression: "selectAll"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.selectAll)
                      ? _vm._i(_vm.selectAll, null) > -1
                      : _vm.selectAll
                  },
                  on: {
                    click: _vm.select,
                    change: function($event) {
                      var $$a = _vm.selectAll,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selectAll = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selectAll = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selectAll = $$c
                      }
                    }
                  }
                }),
                _c("label")
              ])
            ]),
            _vm._l(_vm.thead, function(head, i) {
              return _c("th", {
                key: i,
                staticClass: "table-header",
                domProps: { innerHTML: _vm._s(head.label) }
              })
            })
          ],
          2
        )
      ]),
      _c(
        "tbody",
        { staticClass: "basic-table-body" },
        [
          _vm.loading
            ? _c(
                "tr",
                _vm._l([].concat(Array(5)), function(item, k) {
                  return _c(
                    "td",
                    { key: k },
                    [_c("t-placeholder", { attrs: { lines: 1 } })],
                    1
                  )
                }),
                0
              )
            : _vm._l(_vm.bankslips, function(items, i) {
                return _c(
                  "tr",
                  { key: i },
                  [
                    _c(
                      "td",
                      { staticClass: "center aligned columns extra-options" },
                      [
                        _c("div", { staticClass: "ui checkbox" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selected,
                                expression: "selected"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: _vm.itemId(items),
                              checked: Array.isArray(_vm.selected)
                                ? _vm._i(_vm.selected, _vm.itemId(items)) > -1
                                : _vm.selected
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.selected,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = _vm.itemId(items),
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selected = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selected = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selected = $$c
                                }
                              }
                            }
                          }),
                          _c("label")
                        ])
                      ]
                    ),
                    _vm._l(_vm.formatedData(items), function(item, k) {
                      return _c("td", {
                        key: k,
                        staticClass: "columns",
                        domProps: { innerHTML: _vm._s(item) }
                      })
                    })
                  ],
                  2
                )
              })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }